import { lazy, Suspense } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import theme from './constants/theme';
import Loading from './components/Loading';
import Menu from './components/Menu';
import RequireAuth from './components/RequireAuth';

const Campaign = lazy(() => import('./pages/Campaign'));
const Campaigns = lazy(() => import('./pages/Campaigns'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const EditCampaign = lazy(() => import('./pages/EditCampaign'));
const Login = lazy(() => import('./pages/Login'));
const Users = lazy(() => import('./pages/Users'));
const Withdraw = lazy(() => import('./pages/Withdraw'));
const Withdraws = lazy(() => import('./pages/Withdraws'));

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Menu />}>
          <Route
            index
            element={
              <RequireAuth>
                <Suspense fallback={<Loading isOpen />}>
                  <Dashboard />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="/campaigns"
            element={
              <RequireAuth>
                <Suspense fallback={<Loading isOpen />}>
                  <Campaigns />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="/campaign/edit/:campaignId"
            element={
              <RequireAuth>
                <Suspense fallback={<Loading isOpen />}>
                  <EditCampaign />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="/campaign/:campaignId"
            element={
              <RequireAuth>
                <Suspense fallback={<Loading isOpen />}>
                  <Campaign />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="/users"
            element={
              <RequireAuth>
                <Suspense fallback={<Loading isOpen />}>
                  <Users />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="/withdraws"
            element={
              <RequireAuth>
                <Suspense fallback={<Loading isOpen />}>
                  <Withdraws />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="/withdraw/:withdrawId"
            element={
              <RequireAuth>
                <Suspense fallback={<Loading isOpen />}>
                  <Withdraw />
                </Suspense>
              </RequireAuth>
            }
          />
        </Route>
        <Route
          path="/login"
          element={
            <Suspense fallback={<Loading isOpen />}>
              <Login />
            </Suspense>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
