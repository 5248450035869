import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      contrastText: 'rgba(0, 0, 0, 0.87)',
      light: '#c7c4f6',
      main: '#9892ed',
      dark: '#837aeb',
    },
    secondary: {
      light: '#fea8c7',
      main: '#fe83ae',
      dark: '#fe6098',
    },
    error: {
      light: '#feaeae',
      main: '#FF7979',
      dark: '#e10000',
    },
    warning: {
      light: '#f6d2b0',
      main: '#F1B47C',
      dark: '#c56915',
    },
    info: {
      light: '#94d2e8',
      main: '#4db4d9',
      dark: '#1f7391',
    },
    success: {
      light: '#a9d9d1',
      main: '#70C1B3',
      dark: '#377f73',
    },
  },
});

export default darkTheme;
