import PropTypes from 'prop-types';

import CircularProgress from '@mui/material/CircularProgress';

import { BackdropStyled } from './styles';

export default function Loading({ isOpen }) {
  return (
    <BackdropStyled open={isOpen}>
      <CircularProgress color="inherit" />
    </BackdropStyled>
  );
}
Loading.propTypes = {
  isOpen: PropTypes.bool,
};
Loading.defaultProps = {
  isOpen: false,
};
